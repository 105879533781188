import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  description?: string
  images: ImageProps[]
  logo?: ImageProps
}

export const Brands = memo(function Brands({
  description,
  images,
  logo,
}: Props) {
  if (images.length < 1) {
    return null
  }

  return (
    <Container>
      <Head>
        {logo ? (
          <LazyLoadComponent>
            <Logo {...logo} />
          </LazyLoadComponent>
        ) : null}

        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
      </Head>

      <Wrapper dial={5} row space="between" wrap>
        {images.map((item, index) => (
          <LazyLoadComponent key={index}>
            <Image {...item} />
          </LazyLoadComponent>
        ))}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 1.25rem 0 12.5rem;
  padding: 0 14.236vw;

  @media (max-width: 1023px) {
    margin: 0 0 7.5rem;
    padding: 0 2rem;
  }
`

const Head = styled.div`
  max-width: 18.75rem;
  margin: auto;
  text-align: center;
`

const Logo = styled.img`
  width: auto;
  height: 8.125rem;

  @media (max-width: 424px) {
    max-width: 15.625rem;
    height: auto;
    margin: auto;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark5};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2rem;
`

const Wrapper = styled(FlexBox)`
  max-width: 56.25rem;
  margin: auto;

  picture {
    width: 33.333%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;

    img {
      width: 100%;
      max-width: 8.125rem;
      height: auto;
    }
  }

  @media (max-width: 767px) {
    picture {
      width: 50%;
      margin-top: 2.5rem;

      img {
        max-width: 5rem;
      }
    }
  }
`
