import styled from '@emotion/styled'
import { Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Carousel } from 'app/components/OfferContent/Carousel'
import React, { memo } from 'react'

export interface Props {
  galleryImages: ImageProps[]
  galleryThumbnails?: ImageProps[]
  languageCode: string
}

export const ProShopGallery = memo(function ProShopGallery({
  galleryImages,
  galleryThumbnails,
  languageCode,
}: Props) {
  return (
    <Container>
      {galleryImages ? (
        <Carousel
          images={galleryImages}
          languageCode={languageCode}
          thumbnails={galleryThumbnails}
        />
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  padding: 4.75rem 11.944vw 6.25rem;

  > div {
    &:first-child {
      margin-top: 0 !important;
    }
  }

  @media (max-width: 1023px) {
    padding-right: 1.875rem;
    padding-left: 1.875rem;
  }

  @media (max-width: 767px) {
    padding-bottom: 4.625rem;
  }
`
